import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Image from "@ui/image";
import {
  StyledSection,
  StyledSection2,
  StyledTestimonial,
  StyledTestimonial2,
  StyledReview,
} from "./style";

const EventoArea = ({ id, title, subtitle, desc, photo }) => {
  if (id % 2 === 0) {
    return (
      <StyledSection>
        <Container fluid p={0}>
          <Row alignItems="center">
            <Col md={6} p={0}>
              <div className="leftImg">
                <Image src={photo} alt={title} />
              </div>
            </Col>
            <Col md={6}>
              <StyledTestimonial>
                <Heading as="h4" mb="20px">
                  {title}
                </Heading>
                <StyledReview>{desc}</StyledReview>
              </StyledTestimonial>
            </Col>
          </Row>
        </Container>
      </StyledSection>
    );
  } else {
    return (
      <StyledSection2>
        <Container>
          <Row alignItems="center">
            <Col md={6}>
              <StyledTestimonial2>
                <Heading as="h4" textAlign="center">
                  {title}
                </Heading>
                <Heading as="h5" textAlign="center" mb="20px">
                  {subtitle}
                </Heading>
                <Text textAlign="center">{desc}</Text>
              </StyledTestimonial2>
            </Col>
            <Col md={6} pl="100px">
              <Image src={photo} alt={title} />
            </Col>
          </Row>
        </Container>
      </StyledSection2>
    );
  }
};

export default EventoArea;
