import React from "react";
import { Container } from "@ui/wrapper";
import Heading from "@ui/heading";
import { SectionWrap } from "./style";

const PresentacionArea = () => {
  return (
    <SectionWrap className="project-area">
      <Container>
        <Heading as="h5" color="primary">
          Arraigado en experiencias cultivadas, DK Catering & Eventos trabaja
          con una base de clientes diversa. Brindamos catering fuera de las
          instalaciones para clientes corporativos, sociales e institucionales.
          Nos especializamos en programas de comidas corporativas, bodas y
          eventos en una variedad de lugares selectos en la ciudad de Lima y sus
          alrededores. Nos enorgullecemos de nuestra reputación de honestidad e
          innovación.
        </Heading>
      </Container>
    </SectionWrap>
  );
};

export default PresentacionArea;
