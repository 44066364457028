import React from "react";
import PropTypes from "prop-types";
import Image from "@ui/image";
import Anchor from "@ui/anchor";
import { ImageType } from "@utils/types";
import {
  StyledProject,
  StyledImage,
  StyledLogo,
  StyledContent,
  StyledTitle,
} from "./style";

const Project = ({ title, image, path }) => {
  return (
    <StyledProject>
      <StyledImage>
        {image && <Image src={image} alt={title} className="thumb-image" />}
        {image && (
          <StyledLogo>
            <Image src={image} alt={title} />
          </StyledLogo>
        )}
      </StyledImage>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
      </StyledContent>
      {path && (
        <Anchor path={path} className="link-overlay">
          {title}
        </Anchor>
      )}
    </StyledProject>
  );
};

Project.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(ImageType)).isRequired,
  path: PropTypes.string,
};

export default Project;
