import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { Container } from "@ui/wrapper";
import {
  HeadingType,
  ButtonType,
  ImageType,
  TextType,
  VideoButtonType,
} from "@utils/types";
import { HeroWrapper, HeroContent, HeroSeparator, StyledTitle } from "./style";

const HeroArea = ({ data }) => {
  const heroBg = useStaticQuery(graphql`
    query heroThreeQuery {
      file(relativePath: { eq: "bg/inicio-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 1920
            height: 768
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);

  const bgImage = convertToBgImage(getImage(heroBg.file));

  return (
    <Fragment>
      <HeroWrapper {...bgImage}>
        <Container>
          <HeroContent>
            <StyledTitle>Las fiestas son nuestra pasión</StyledTitle>
          </HeroContent>
        </Container>
        <HeroSeparator>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            preserveAspectRatio="none"
            viewBox="0 0 100 100"
          >
            <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
          </svg>
        </HeroSeparator>
      </HeroWrapper>
    </Fragment>
  );
};

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    video_button: PropTypes.shape(VideoButtonType),
  }),
};

export default HeroArea;
