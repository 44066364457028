import React from "react";
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import { useStaticQuery, graphql } from "gatsby";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
import Motto from "@components/motto";
import Project from "@components/project/layout-01";
import { SectionTitleType, ItemType, MottoType } from "@utils/types";
import { SectionWrap } from "./style";

const sliderSettings = {
  slidesPerView: 3,
  loop: true,
  spaceBetween: 30,
  autoplay: false,
  pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
};

const ProjectArea = () => {
  const data = useStaticQuery(graphql`
    {
      catering: file(relativePath: { eq: "claim/catering.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      dj: file(relativePath: { eq: "claim/dj.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      equipos: file(relativePath: { eq: "claim/equipos.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      grupo: file(relativePath: { eq: "claim/grupo.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      horaloca: file(relativePath: { eq: "claim/horaloca.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      mozos: file(relativePath: { eq: "claim/mozos.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pantallas: file(relativePath: { eq: "claim/pantallas.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      pistaled: file(relativePath: { eq: "claim/pistaled.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      video: file(relativePath: { eq: "claim/video.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const items = [
    { title: "Catering", image: data.catering },
    { title: "Staff de Djs", image: data.dj },
    { title: "Equipos de Sonido", image: data.equipos },
    { title: "Grupos Electrógenos", image: data.grupo },
    { title: "Hora Loca", image: data.horaloca },
    { title: "Staff de Mozos", image: data.mozos },
    { title: "Pantallas LED", image: data.pantallas },
    { title: "Pista de baile LED", image: data.pistaled },
    { title: "Video y Foto", image: data.video },
  ];
  return (
    <SectionWrap className="project-area">
      <Container>
        <SectionTitle
          mb={["30px", null, "60px"]}
          subtitle=" Si puedes soñarlo, ¡podemos atenderte!"
          title="Somos una empresa de catering de servicio completo."
        />
        <SwiperSlider
          dotStyle={2}
          paginationTop="60px"
          options={sliderSettings}
        >
          {items.map((project, i) => (
            <SwiperSlide key={i}>
              <Project
                title={project.title}
                image={project.image}
                path="/servicios"
              />
            </SwiperSlide>
          ))}
        </SwiperSlider>
        <Motto
          mt={["40px", null, "80px"]}
          text="¿Listo para comenzar?"
          linkText="Contáctanos"
          path="/contacto"
        />
      </Container>
    </SectionWrap>
  );
};

ProjectArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    motto: PropTypes.shape(MottoType),
  }),
};

export default ProjectArea;
