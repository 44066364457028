import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.section`
  background-color: ${themeGet("colors.gray.100")};
  text-align: center;
  padding-block-start: 60px;
  ${device.medium} {
    padding-block-start: 80px;
  }
  ${device.large} {
    padding-block-start: 100px;
  }
`;
