import React from "react";
import PropTypes from "prop-types";
import Heading from "@ui/heading";
import { useStaticQuery, graphql } from "gatsby";
import Accordion from "@ui/accordion";
import Image from "@ui/image";
import { Container, Row, Col } from "@ui/wrapper";
import {
  SectionTitleType,
  ItemType,
  VideoButtonType,
  ImageType,
} from "@utils/types";
import { SectionWrap } from "./style";

const FaqArea = ({ space }) => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "faq.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const items = [
    {
      id: "uno",
      title: "¿Cuánto tiempo de aviso se requiere?",
      description:
        "Según el tamaño y el alcance de su evento, normalmente requerimos un aviso mínimo de dos semanas para eventos corporativos y catering corporativo.",
    },
    {
      id: "dos",
      title: "¿Cuál es el tamaño mínimo de pedido/número de invitados?	",
      description: "Nuestro pedido mínimo suele ser de 25 personas.",
    },
    {
      id: "tres",
      title: "	¿Proporcionan artículos empaquetados individualmente?	",
      description:
        "Los artículos empacados individualmente están disponibles a pedido y pueden requerir tarifas adicionales.",
    },
    {
      id: "cuatro",
      title: "¿Puedes ser algo más que nuestro servicio de catering?	",
      description:
        "Trabajamos en colaboración en todos los aspectos de un evento. Esto incluye (pero no se limita a) selecciones de alimentos y bebidas, música, audiovisuales, decoración, bolsas de regalo, etc. Tenemos paquetes de catering extensos y diversos, y nuestros profesionales culinarios personalizarán los menús para temas y eventos específicos según sea necesario.",
    },
  ];
  return (
    <SectionWrap $space={space}>
      <Container>
        <Heading color="primary" fontAlign="center">
          Preguntas frecuentes
        </Heading>

        <Row>
          <Col lg={6}>
            {items && (
              <Accordion disableDefaultActive layout={2} data={items} />
            )}
          </Col>
          <Col lg={6} mt={["20px", null, null, 0]}>
            <Image src={data.file} alt="FaqArea" />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

FaqArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    video_button: PropTypes.shape(VideoButtonType),
  }),
  space: PropTypes.oneOf([1, 2]),
};

FaqArea.defaultProps = {
  space: 1,
};

export default FaqArea;
