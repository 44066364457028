import styled, { device, themeGet } from "@styled";
import BackgroundImage from "gatsby-background-image";

export const HeroWrapper = styled(BackgroundImage)`
  min-height: 590px;
  display: flex;
  position: relative;
  align-items: center;
  background-attachment: scroll;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  ${device.medium} {
    min-height: 670px;
  }
  ${device.xlarge} {
    min-height: 740px;
  }
  ${device.xxlarge} {
    min-height: 880px;
  }
`;

export const HeroSeparator = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  line-height: 0;
  bottom: 0;
  svg {
    fill: ${themeGet("colors.gray.100")};
    height: 100px;
    width: 100%;
  }
  i {
    color: ${themeGet("colors.gray.100")}
    height: 100px;
    width: 100%;
  }
`;

export const HeroContent = styled.div`
  text-align: center;
`;

export const StyledTitle = styled.h3`
  font-style: italic;
  text-transform: uppercase;
  font-weight: 900;
  font-size: clamp(40px, 6.3vw, 120px);
  color: ${themeGet("colors.white")};
  line-height: 1.09;
  margin-block-end: 20px;
  ${device.xlarge} {
    margin-block-end: 30px;
  }
`;
