import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import { useStaticQuery, graphql } from "gatsby";
import EventoArea from "@components/evento-area";
import { SectionWrap } from "./style";

const EventosArea = () => {
  const data = useStaticQuery(graphql`
    {
      corporativos: file(relativePath: { eq: "eventos-corporativos.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      catering: file(
        relativePath: { eq: "catering-para-bodas-y-eventos.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      especialidad: file(relativePath: { eq: "especialidad.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const eventos = [
    {
      title: "Eventos Corporativos",
      desc: "Nuestros servicios de catering corporativo y planificación de eventos incluyen una amplia gama de estilos de eventos con capacidad para entre 50 y más de 3000 invitados. Nuestro trabajo se extiende por toda la ciudad de Lima. Desde galas y eventos para recaudar fondos hasta almuerzos gourmet, cócteles, eventos para los medios y cenas, estamos orgullosos de celebrar la diversidad de nuestra comida en la diversificación de nuestros menús de catering. Podemos acomodar cualquier preferencia dietética, incluido el catering sin nueces, sin lácteos, sin gluten, vegetariano y vegano y más. Contáctenos para que podamos comenzar a planificar tu evento corporativo de inmediato.",
      photo: data.corporativos,
    },
    {
      title: "Nuestra Especialidad",
      subtitle: "Un evento impresionante con comida que se derrite en la boca.",
      desc: "Con una decoración hermosa y alquileres únicos, transformaremos tu hogar o lugar de eventos en un telón de fondo fascinante para sus nuevos recuerdos. El día de tu fiesta, no tendrás que preocuparte por cómo van las cosas entre bastidores. Nuestro talentoso y atento personal se asegurará de que todo se ejecute a la perfección. Además, estarás demasiado ocupado mezclándote con los invitados y disfrutando de entremeses que son tan buenos que todos querrán seguir comiendo durante toda la noche.",
      photo: data.especialidad,
    },
    {
      title: "Catering para bodas y eventos",
      desc: "DK Catering & Eventos ha sido líder en la creación de bodas y eventos especiales que unen a las personas. Nuestras capacidades se expanden más allá de la comida al diseño y la producción de eventos a gran escala. Podemos ayudarte con todos los niveles de planificación para garantizar que cada detalle se gestione con cuidado y una ejecución impecable. ¿Buscas un local? Atendemos en muchos de los mejores lugares para bodas de Lima. Nuestros estilos de catering para bodas incluyen cenas emplatadas, catering buffet, estaciones de acción y aperitivos pasados, entre muchos otros. Póngase en contacto para comenzar a planificar su boda hoy.",
      photo: data.catering,
    },
  ];
  return (
    <SectionWrap>
      <Container fluid>
        <Row>
          <Col>
            {eventos.map((evento, i) => (
              <EventoArea
                id={i}
                title={evento.title}
                subtitle={evento.subtitle}
                desc={evento.desc}
                photo={evento.photo}
              />
            ))}
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default EventosArea;
