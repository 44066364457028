import styled, { device, themeGet } from "@styled";

export const StyledSection = styled.section`
  padding-block: 60px;
  position: relative;
  ${device.medium} {
    padding-block: 80px;
  }
  ${device.large} {
    padding-block: 120px;
  }
`;

export const StyledSection2 = styled.section`
  background-color: ${themeGet("colors.gray.700")};
  padding-block: 60px;
  position: relative;
  ${device.medium} {
    padding-block: 80px;
  }
  ${device.large} {
    padding-block: 120px;
  }
`;

export const StyledTestimonial = styled.div`
  max-width: 570px;
  margin-block-start: 30px;
  padding-left: 100px;
  position: relative;
  z-index: 1;
  ${device.medium} {
    margin-block-start: 0;
  }
`;

export const StyledTestimonial2 = styled.div``;

export const StyledReview = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
`;
