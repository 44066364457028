import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.section`
  padding-block-start: 60px;
  padding-block-end: 52px;
  ${device.medium} {
    padding-block-start: 80px;
    padding-block-end: 72px;
  }
  ${device.large} {
    padding-block-start: 100px;
    padding-block-end: 92px;
  }
`;

export const BoxFoto = styled.div`
  border-radius: ${themeGet("radii.md")};
  box-shadow: ${themeGet("shadows.default")};
  overflow: hidden;
  .gatsby-image-wrapper,
  img {
    width: 100%;
  }
`;
