import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import HeroArea from "@containers/index/hero/layout-05";
import AcercaArea from "@containers/index/acerca/layout-04";
import Eventos from "@containers/index/eventos";
import ProjectArea from "@containers/index/project";
import FaqArea from "@containers/index/faq";
import PresentacionArea from "@containers/index/presentacion";
import TestimoniosArea from "@containers/index/testimonios/layout-02";
import Footer from "@layout/footer/layout-01";

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Inicio" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <HeroArea />
        <PresentacionArea />
        <TestimoniosArea />
        <Eventos />
        <ProjectArea />
        <FaqArea />
        <AcercaArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
