import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
import Testimonial from "@components/testimonial/layout-02";
import { TestimonialWrapper } from "./style";

const sliderSettings = {
  slidesPerView: 3,
  centeredSlides: true,
  loop: true,
  speed: 1000,
  spaceBetween: 50,
  pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    991: {
      slidesPerView: 2,
    },
    1499: {
      slidesPerView: 3,
    },
  },
};

const TestimonialArea = () => {
  const testimoniosData = useStaticQuery(graphql`
    {
      allPrismicTestimonio {
        edges {
          node {
            data {
              titulo {
                text
              }
              texto {
                text
              }
              nombre {
                text
              }
            }
          }
        }
      }
    }
  `);
  const testimonios = testimoniosData.allPrismicTestimonio.edges;
  return (
    <TestimonialWrapper>
      <Container>
        <SectionTitle
          mb={["50px", null, "60px"]}
          title="Testimonios"
          subtitle="¿Qué dicen nuestros clientes?"
        />
        {testimonios && (
          <SwiperSlider options={sliderSettings} paginationTop="80px">
            {testimonios.map(
              (
                {
                  node: {
                    data: { titulo, texto, nombre },
                  },
                },
                i
              ) => (
                <SwiperSlide key={`swiper-${i}`}>
                  <Testimonial
                    authorName={nombre.text}
                    authorRole={titulo.text}
                    review={texto.text}
                  />
                </SwiperSlide>
              )
            )}
          </SwiperSlider>
        )}
      </Container>
    </TestimonialWrapper>
  );
};

export default TestimonialArea;
