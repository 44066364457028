import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import Text from "@ui/text";
import Button from "@ui/button";
import SectionTitle from "@ui/section-title";
import { SectionWrap, BoxFoto } from "./style";

const AboutArea = () => {
  const AcercaData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "acerca-catering.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 570, height: 350)
        }
      }
    }
  `);
  const foto = AcercaData.file.childImageSharp.gatsbyImageData;
  return (
    <SectionWrap>
      <Container>
        <SectionTitle
          mb={["47px", null, "60px"]}
          title="Ofrecemos servicio completo de catering"
          subtitle="Acerca de DK Catering & Eventos"
        />
        <Row>
          <Col lg={{ span: 4, offset: 1 }} mb={["10px", null, null, 0]}>
            <BoxFoto>
              <Image src={foto} alt="DK Catering & Eventos" />
            </BoxFoto>
          </Col>
          <Col lg={{ span: 5, offset: 1 }}>
            <Text fontSize="18px" mb="20px">
              DK Catering & Eventos ofrece servicio completo de catering, que
              atiende fiestas y eventos sociales y corporativos. Ya sea que esté
              planeando un evento corporativo en Lima, una lujosa boda en una
              mansión de Pachacamac, una velada en las playas del sur o una
              celebración privada en casa, DK Catering & Eventos es la compañía
              de catering preferida de Lima.
            </Text>
            <Button path="#" fontSize="15px">
              Cotiza tu evento
            </Button>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default AboutArea;
