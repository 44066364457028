import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@utils/types";
import {
  TestimonialWrap,
  TestimonialInfo,
  TestimonialAuthor,
  AuthorInfo,
  AuthorName,
  AuthorRole,
  Review,
} from "./style";

const Testimonial = ({ authorName, authorRole, review, ...rest }) => {
  return (
    <TestimonialWrap {...rest}>
      <TestimonialInfo>
        <TestimonialAuthor>
          <AuthorInfo>
            {authorName && <AuthorName>{authorName}</AuthorName>}
            {authorRole && <AuthorRole>{authorRole}</AuthorRole>}
          </AuthorInfo>
        </TestimonialAuthor>
      </TestimonialInfo>
      {review && <Review>{review}</Review>}
    </TestimonialWrap>
  );
};

Testimonial.propTypes = {
  authorImg: PropTypes.shape(ImageType),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authorName: PropTypes.string,
  authroRole: PropTypes.string,
  review: PropTypes.string,
};

export default Testimonial;
